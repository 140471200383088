<template>
  <div class="d-flex flex-wrap justify-content-center small legend">
    <div
      v-for="(legend, legendIndex) in legends"
      :key="`key-${legendIndex}`"
      class="d-flex align-items-center legend-item"
    >
      <div
        :style="{ backgroundColor: legend.color }"
        class="indicator flex-shrink-0 me-2"
      ></div>
      <div class="lh-sm">{{ legend.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NutritionLegend',
  props: {
    legends: {
      type: Array,
      required: true,
    },
  },
};
</script>
